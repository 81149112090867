import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import { Container } from "react-styledcomponent-template";
import useAuthCheck from "../apis/auth/check";
import { useCookies } from "react-cookie";

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const ContentContainer = styled(Container)`
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 20px;
  align-items: start;
  justify-content: start;
`;

interface TemplateProps {
  children?: React.ReactNode;
}
const Template: React.FC<TemplateProps> = (p) => {
  const auth = useAuthCheck();
  const [, setCookie] = useCookies(["login"]);

  useEffect(() => {
    try {
      if (auth.loading) return;
      if (!auth.data) return;
      const isLoggedin = !!auth.data?.isLoggedin;
      setCookie("login", isLoggedin);
    } catch (e) {
      setCookie("login", false);
    }
  }, [auth]);
  return (
    <BodyContainer>
      <Header />
      <ContentContainer>{p?.children}</ContentContainer>
      <Footer />
    </BodyContainer>
  );
};

export default Template;
