import useApi from "../../libs/useApi";

interface DataType {
  message: string;
}
interface Params {}

const path = "/auth/logout";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
