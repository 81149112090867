import React from "react";
import { Head, Button, Label } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import Template from "../template";
import useMessages from "../apis/message/list";
import usePostMessage from "../apis/message/add";
import FileIcon from "../components/FileIcon";
import moment from "moment";

const Header = styled(Head)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin: 0;
  width: 100%;
  height: 60px;
  background-color: var(--default-bg-color);
`;
const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: fit-content;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  background-color: var(--default-bg-color);
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
`;

const Spacer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;

const StyledLabel = styled(Label)`
  flex-grow: 1;
`;
const Files = styled.label`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
  height: 44px;
`;
const Attachement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: fit-content;
  padding: 5px 10px;
  max-width: 100px;
  background-color: var(--default-primary-bg-color);
  border-radius: 4px;
`;
const AttachementName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: var(--color-text-default);
`;
const AttachementIcon = styled(FileIcon)`
  width: auto;
  height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledFileIcon = styled(FileIcon)`
  width: auto;
  height: 30px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
`;
const MessageUserContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  text-decoration: none;
  color: var(--default-text-color);
`;
const MessageUserImage = styled.div<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const MessageUserName = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text-default);
`;
const MessageText = styled.div`
  display: flex;
  font-size: 14px;
  color: var(--color-text-default);
  text-align: left;
  word-break: break-all;
  width: 100%;
`;

const InputText = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const MessageRequest = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const MessageRequestDatetime = styled.div``;
const MessageImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  overflow-x: auto;
`;
const MessageImage = styled.span<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 150px;
`;

const MessageFiles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`;
const MessageFile = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--default-primary-bg-color);
  padding: 10px 0;
`;
const MessageFileIcon = styled(FileIcon)`
  width: auto;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`;
const MessageFileText = styled.div`
  font-size: 6px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("idがありません");
  const messages = useMessages({ pathParams: { memberId: id } });
  const postMessage = usePostMessage();
  const messageRef = React.useRef<HTMLTextAreaElement>(null);

  const [files, setFiles] = React.useState<File[]>([]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const text = e.currentTarget?.message?.value;
    if (!text) return;

    const file1 = files?.[0];
    const file2 = files?.[1];
    const file3 = files?.[2];
    const file4 = files?.[3];

    await postMessage.execute({
      params: {
        to: parseInt(id),
        message: text,
        ...(file1 && { file1 }),
        ...(file2 && { file2 }),
        ...(file3 && { file3 }),
        ...(file4 && { file4 }),
      },
    });

    await messages.execute({ pathParams: { memberId: id } });

    if (messageRef?.current?.value) messageRef.current.value = "";
  };

  const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleAddFile", e.target.files?.[0]);
    const file = e.target.files?.[0];
    if (!file) return;
    setFiles([...files, file]);
  };

  return (
    <Template>
      <Header>チャット</Header>
      {messages.data?.map((message) => (
        <MessageContainer>
          <MessageUserContainer to={`/profile/${message.member1.id}`}>
            <MessageUserImage src={message.member1.icon || ""} />
            <MessageUserName>{message.member1.name}</MessageUserName>
          </MessageUserContainer>
          <MessageText>{message.message}</MessageText>
          {message?.request && (
            <MessageRequest>
              {message?.request?.deadline?.date && (
                <MessageRequestDatetime>
                  締切:{" "}
                  {moment(message?.request?.deadline?.date).format(
                    "YYYY.MM.DD"
                  )}
                </MessageRequestDatetime>
              )}
              {message?.request?.images?.length && (
                <MessageImages>
                  {message.request.images.map((image) => (
                    <MessageImage src={image} />
                  ))}
                </MessageImages>
              )}
            </MessageRequest>
          )}
          {message?.files?.length ? (
            <MessageFiles>
              {message.files.map((file, index) => (
                <MessageFile href={file}>
                  <MessageFileIcon />
                  <MessageFileText>ダウンロード</MessageFileText>
                </MessageFile>
              ))}
            </MessageFiles>
          ) : (
            ""
          )}
        </MessageContainer>
      ))}

      <Spacer />
      <Footer>
        <Files>
          <StyledFileIcon />
          <input type="file" name="file" onChange={handleAddFile} hidden />
          {files?.length === 0 ? <>ファイルを添付</> : ""}
          {files?.map?.((file) => (
            <Attachement>
              <AttachementIcon />
              <AttachementName>{file?.name}</AttachementName>
            </Attachement>
          )) || ""}
        </Files>
        <Form onSubmit={handleSubmit}>
          <StyledLabel label="メッセージ">
            <InputText
              placeholder="メッセージを入力"
              name="message"
              ref={messageRef}
            />
          </StyledLabel>
          <Button color="primary">送信</Button>
        </Form>
      </Footer>
    </Template>
  );
};

export default Page;
