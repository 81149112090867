import React from "react";
import { Head } from "react-styledcomponent-template";
import styled from "styled-components";
import Template from "../template";

const Header = styled(Head)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin: 0;
  width: 100%;
  height: 60px;
  background-color: var(--default-bg-color);
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  &:after {
    display: flex;
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom: 1px solid var(--primary-bg-color);
  }
`;
const Key = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 150px;
`;
const Value = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;
const items = [
  {
    key: "会社名",
    value: "VALUEARCHITECTS株式会社",
  },
  {
    key: "会社名（カナ）",
    value: "バリューアーキテクツカブシキガイシャ",
  },
  {
    key: "代表者名",
    value: "藤本隆太郎",
  },
  {
    key: "代表者名（カナ）",
    value: "フジモトリュウタロウ",
  },
  {
    key: "郵便番号",
    value: "〒141-0022",
  },
  {
    key: "住所",
    value: "東京都品川区東五反田３丁目１７−２１, Damo Build, 401号室",
  },
  {
    key: "電話番号",
    value: "03-1234-5678",
  },
  {
    key: "FAX番号",
    value: "03-1234-5679",
  },
  {
    key: "メールアドレス",
    value: "developer@value-arc.com",
  },
];

const Page: React.FC = () => {
  return (
    <Template>
      <Header>会社</Header>
      <Items>
        {items.map((item) => (
          <Item key={item.key}>
            <Key>{item.key}</Key>
            <Value>{item.value}</Value>
          </Item>
        ))}
      </Items>
    </Template>
  );
};

export default Page;
