import styled from "styled-components";

// const Label = styled.label`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   padding: 0;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

// interface Props {
//   name: string;
// }

// const Switch: React.FC<Props> = (p) => {
//   return <Label></Label>;
// };

// export default Switch;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  width: 40px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  &::before {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid var(--primary-bg-color);
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;
  }
  &::after {
    background: var(--info-bg-color);
    box-sizing: border-box;
    content: "";
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 10px), -50%);
    transition: 0.2s ease-in-out;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  &:checked::after {
    background: var(--primary-bg-color);
    transform: translate(calc(-50% + 10px), -50%);
  }
`;

export default Checkbox;
