import useApi from "../../libs/useApi";

interface DataType {
  id: number;
}
interface Params {
  images: File[];
  name: string;
  deadline?: string;
}

const path = "/request";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
