import React, { useRef, useCallback, useState } from "react";
import { Head, Button, Label, Input } from "react-styledcomponent-template";
import Webcam from "react-webcam";
import styled from "styled-components";
import base64toFile from "../libs/base64toFile";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Template from "../template";
import useCreateRequest from "../apis/request/create";
import Checkbox from "../components/Checkbox";
// import LabelText from "../components/LabelText";
import moment from "moment";

const WIDTH = 300;
const ASPECT_RATIO = 4 / 3;
const PREVIEW_WIDTH = 150;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const videoConstraints = {
  width: WIDTH,
  height: WIDTH * ASPECT_RATIO,
  facingMode: "environment",
};

const WebcamContainer = styled.div`
  width: 100%;
  align-self: center;
  max-width: ${WIDTH * 2}px;
  min-height: ${WIDTH * ASPECT_RATIO}px;
  padding-bottom: ${WIDTH * ASPECT_RATIO}px;
  position: relative;
`;
const webcamStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
};

const TakePhotoButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;

const Nofile = styled.div`
  width: ${PREVIEW_WIDTH}px;
  height: ${PREVIEW_WIDTH * ASPECT_RATIO}px;
`;

const Preview = styled.div<{ src: string }>`
  width: ${PREVIEW_WIDTH}px;
  height: ${PREVIEW_WIDTH * ASPECT_RATIO}px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
  position: relative;
`;
const DeleteButton = styled.button`
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
  justify-content: center;
  align-items: center;
  display: flex;
`;

const FileInput = styled.label`
  width: 100%;
  height: 40px;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
`;

const DatetimeContainer = styled.div`
  width: 100%;
`;
const DatetimeEnable = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const DatetimeLabel = styled(Label)`
  margin-top: 10px;
  display: none;
  height: fit-content;

  *:has(:checked) & {
    display: flex;
  }
`;

function convertImageToPNG(image: File): Promise<File> {
  return new Promise((resolve, reject) => {
    // FileReaderを使用して画像ファイルを読み込む
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        // canvas要素を作成して画像を描画
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);

        // canvasの内容をPNG形式で出力
        canvas.toBlob((blob) => {
          if (blob) {
            // BlobをFileオブジェクトに変換してresolve
            const pngFile = new File([blob], "converted_image.png", {
              type: "image/png",
            });
            resolve(pngFile);
          } else {
            reject(new Error("Canvas to Blob conversion failed"));
          }
        }, "image/png");
      };
      img.onerror = () => reject(new Error("Image load failed"));
      img.src = e.target?.result as string;
    };
    reader.onerror = () => reject(new Error("FileReader failed"));
    reader.readAsDataURL(image);
  });
}

const Page: React.FC = () => {
  const navigate = useNavigate();
  const createRequest = useCreateRequest();
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]); // [File, File, File]
  const enableWebcam = () => setIsEnable(true);
  const webcamRef = useRef<Webcam>(null);

  const popFile =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      setFiles((f) => f.filter((_, i) => i !== index));
    };
  const handleFileAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target?.files?.[0];
    if (!f) return;
    console.log(f);
    setFiles((fs) => [f, ...fs]);
  };
  const capture = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const imageSrc = webcamRef.current?.getScreenshot();
      if (!imageSrc) return;
      const file = base64toFile(imageSrc, uuidv4());
      setFiles((f) => [file, ...f]);
    },
    [webcamRef]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!files.length) return alert("ファイルを追加してください");
    const name = e.currentTarget?.note?.value;
    if (!name) return alert("名前を入力してください");

    const deadlineEnabled = e.currentTarget.deadlineEnabled.checked;

    const deadline = deadlineEnabled
      ? e.currentTarget.deadline.value
      : undefined;

    const pngfiles = await Promise.all(
      files?.map((file) => {
        return convertImageToPNG(file);
      })
    );

    const result = await createRequest.execute({
      params: { images: pngfiles, name, deadline },
    });
    if (result instanceof Error) return;
    navigate(`/result/${result.id}`);
  };

  const today = moment().format("YYYY-MM-DD");

  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Head>書類を撮影</Head>
        {!isEnable && (
          <Button color="primary" onClick={enableWebcam} fullWidth>
            カメラを起動する
          </Button>
        )}
        {isEnable && (
          <WebcamContainer>
            <Webcam
              style={webcamStyle}
              audio={false}
              width={300}
              height={400}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <TakePhotoButton onClick={capture} />
          </WebcamContainer>
        )}
        <FileInput>
          ファイルを追加
          <input type="file" accept="image/*" hidden onChange={handleFileAdd} />
        </FileInput>
        <PreviewContainer>
          {files.length > 0 ? (
            files?.map?.((file, index) => (
              <Preview key={index} src={URL.createObjectURL(file)}>
                <DeleteButton onClick={popFile(index)} children={"×"} />
              </Preview>
            ))
          ) : (
            <Nofile>ファイルを添付してください</Nofile>
          )}
        </PreviewContainer>
        <Label fullWidth label="名前">
          <Input fullWidth name="note" placeholder="メモなどを追加" />
        </Label>
        <DatetimeContainer>
          <DatetimeEnable>
            期限を設定する
            <Checkbox name="deadlineEnabled" />
          </DatetimeEnable>
          <DatetimeLabel fullWidth label="名前">
            <Input fullWidth type="date" name="deadline" defaultValue={today} />
          </DatetimeLabel>
        </DatetimeContainer>
        {/* <LabelText>
          顧問弁護士がいた場合自動で依頼する
          <Checkbox name="is_public" />
        </LabelText>
        <LabelText>
          顧問料以外に料金が発生した場合に次回顧問料支払い時に請求する
          <Checkbox name="is_public" />
        </LabelText> */}
        <Button color="primary" fullWidth loading={createRequest.loading}>
          送信する
        </Button>
      </Form>
    </Template>
  );
};

export default Page;
