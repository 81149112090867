import React from "react";
import { Head, Text } from "react-styledcomponent-template";
import LinkedButton from "../components/LinkedButton";
import Template from "../template";

const Page: React.FC = () => {
  return (
    <Template>
      <Head>トップページ(ログイン前)</Head>
      <Text>今後アップデートでリッチになる予定です。</Text>
      <LinkedButton to="/login">ログイン</LinkedButton>
    </Template>
  );
};

export default Page;
