import React from "react";
import { Head } from "react-styledcomponent-template";
import LinkedButton from "../components/LinkedButton";
import { useCookies } from "react-cookie";
import Template from "../template";
import useSignout from "../apis/auth/signout";

const Page: React.FC = () => {
  const [, , removeCookie] = useCookies(["login"]);
  const signout = useSignout();
  const logout = async () => {
    const result = await signout.execute({});
    if (result instanceof Error) return;
    removeCookie("login");
  };
  return (
    <Template>
      <Head>ダッシュボード</Head>
      <LinkedButton to="/scan">書類を添付して依頼</LinkedButton>
      <LinkedButton to="/chat">チャット一覧</LinkedButton>
      <LinkedButton to="/result">
        依頼・分析中の案件(未依頼案件)一覧
      </LinkedButton>
      {/* <LinkedButton to="/case">依頼中の案件</LinkedButton> */}
      <LinkedButton to="/profile">自分のプロフィールを表示</LinkedButton>
      {/* <LinkedButton to="/diagnosis">依頼(専門家向け機能)</LinkedButton> */}
      {/* <LinkedButton to="/diagnosis/add">
        依頼を追加（管理者の機能）
      </LinkedButton> */}
      <LinkedButton to="#" onClick={logout}>
        ログアウト
      </LinkedButton>
    </Template>
  );
};

export default Page;
