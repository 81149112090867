import { decode as atob } from "base-64";
import Blob from "cross-blob";

const base64toFile = (base64image: string, filename: string): File => {
  const parts = base64image.split(";base64,");
  const imageType = parts[0].split(":")[1];
  const decodedData = atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: imageType });
  const file = new File([blob], `${filename}.jpg`, { type: "image/jpeg" });
  return file;
};

export default base64toFile;
