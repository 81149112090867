import React from "react";
import { Head, Button } from "react-styledcomponent-template";
import styled from "styled-components";
import Template from "../template";
import useUser from "../apis/user/get";
import { useParams } from "react-router-dom";

const UserImage = styled.div<{ src?: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-self: center;
`;
const UserName = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text);
  align-self: center;
`;
const Company = styled.div``;
const Description = styled.div``;
const Introduction = styled.div``;

const Professions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding: 0;
`;
const Profession = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--secondary-text-color);
  background-color: var(--secondary-bg-color);
  padding: 5px;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
`;
const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("id not found");
  const [isFollowed, setIsFollowed] = React.useState<boolean>(false);
  const user = useUser({ pathParams: { id } });
  const toggleFollowed = (e: React.MouseEvent<HTMLButtonElement>) =>
    setIsFollowed((f) => !f);

  return (
    <Template>
      <Head>プロフィール</Head>
      <UserImage src={user.data?.icon} />
      <UserName>{user.data?.name}</UserName>

      <Professions>
        {user.data?.profession?.map((profession) => (
          <Profession>{profession}</Profession>
        ))}
      </Professions>
      <Company>
        {user.data?.company} {user.data?.position}
      </Company>
      <Description>{user.data?.description}</Description>
      <Introduction>{user.data?.introduction}</Introduction>
      <Button
        color={isFollowed ? "info" : "primary"}
        fullWidth
        onClick={toggleFollowed}
      >
        {isFollowed ? "フォロー中" : "フォロー"}
      </Button>
    </Template>
  );
};

export default Page;
