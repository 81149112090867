import React from "react";
import { Head, Text } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Template from "../template";
import useRequests from "../apis/request/list";

const Item = styled(Link)<{ src: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;
  &:before {
    content: " ";
    display: flex;
    background-color: white;
    width: 100%;
    padding-top: ${(1 / 4) * 100}%;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
  }
`;

const Page: React.FC = () => {
  const requsets = useRequests();

  return (
    <Template>
      <Head>案件一覧</Head>
      <Text>まだ依頼をしていない案件の一覧です。依頼しましょう</Text>
      {requsets?.data
        ?.filter((i) => !i?.isSubmited)
        ?.map?.((i) => (
          <Item src={i?.images?.[0]} to={`/result/${i.id}`}>
            <Text>{i?.name}</Text>
          </Item>
        ))}
    </Template>
  );
};

export default Page;
