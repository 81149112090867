import styled from "styled-components";
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default Form;
