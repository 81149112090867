import React from "react";
import {
  Head,
  Text,
  Button,
  Label,
  Input,
} from "react-styledcomponent-template";
import styled from "styled-components";
import {
  useNavigate,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Template from "../template";
import useDiagnosis from "../apis/diagnosis/get";
import useUpdateRequest from "../apis/request/update";

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Image = styled.div<{ src?: string }>`
  display: flex;
  background-color: white;
  width: 100%;
  padding-top: ${(1 / 4) * 100}%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
`;
const RequestDestination = styled.label`
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  &:has(*:checked) {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
  }
`;
const UserImage = styled.div<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex: 0 0 50px;
`;
const Username = styled.div`
  display: flex;
  flex: 0 0 12px;
  font-size: 12px;
`;
const LinkToProfile = styled(Link)`
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 5px;
`;

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("requestId");
  if (!requestId) throw new Error("requestId is not found");
  const navigate = useNavigate();
  const diagnosis = useDiagnosis({ pathParams: { id: `${id}` } });
  const updateRequest = useUpdateRequest();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const description = e.currentTarget?.description?.value;
    const destination = Number(e.currentTarget?.destination?.value);
    const plan = Number(id);
    const __id = Number(requestId);

    console.log({ id: __id, description, plan, destination });

    const result = await updateRequest.execute({
      params: { id: __id, description, plan, destination },
    });
    if (result instanceof Error) return;
    navigate(`/chat/${__id}`);
  };

  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Head>依頼</Head>
        <Image src={diagnosis?.data?.image} />
        <Text>
          見積もり相場：￥{diagnosis?.data?.marketPrice?.toLocaleString?.()}
        </Text>
        <Text>内容：{diagnosis?.data?.description}</Text>
        <Label label="詳細" fullWidth>
          <Input placeholder="詳細を入力" fullWidth name="description" />
        </Label>
        <Text>依頼先を選んでください</Text>
        <UserContainer>
          {diagnosis?.data?.user?.map?.((user) => (
            <RequestDestination>
              <UserImage src={user?.icon || ""} />
              <Username>{user?.name}</Username>
              <LinkToProfile to={`/profile/${user.id}`}>確認する</LinkToProfile>
              <input type="radio" name="destination" value={user.id} hidden />
            </RequestDestination>
          ))}
        </UserContainer>
        <Button color="primary" fullWidth>
          依頼する
        </Button>
      </Form>
    </Template>
  );
};

export default Page;
