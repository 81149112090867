interface IconProps extends React.SVGProps<SVGSVGElement> {}

const Icon: React.FC<IconProps> = (p) => (
  <svg
    width="176"
    height="208"
    viewBox="0 0 176 208"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...p}
  >
    <path
      d="M173.66 58.34L117.66 2.34C116.917 1.59733 116.034 1.00842 115.063 0.606911C114.092 0.205406 113.051 -0.000823887 112 2.47359e-06H16C11.7565 2.47359e-06 7.68687 1.68571 4.68629 4.68629C1.68571 7.68688 0 11.7565 0 16V192C0 196.243 1.68571 200.313 4.68629 203.314C7.68687 206.314 11.7565 208 16 208H160C164.243 208 168.313 206.314 171.314 203.314C174.314 200.313 176 196.243 176 192V64C176.001 62.9491 175.795 61.9084 175.393 60.9372C174.992 59.966 174.403 59.0835 173.66 58.34ZM120 27.31L148.69 56H120V27.31ZM160 192H16V16H104V64C104 66.1217 104.843 68.1566 106.343 69.6569C107.843 71.1571 109.878 72 112 72H160V192Z"
      fill="black"
    />
  </svg>
);

export default Icon;
