import React from "react";
import { useCookies } from "react-cookie";
import Dashboard from "./Dashboard";
import Landing from "./Landing";

const Page: React.FC = () => {
  const [cookies] = useCookies(["login"]);
  if (cookies?.login === "true") return <Dashboard />;
  return <Landing />;
};

export default Page;
