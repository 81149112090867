import useApi from "../../libs/useApi";

interface DataType {}
interface Params {
  image: File;
  name: string;
  description: string;
  marketPrice: number;
}

const path = "/diagnosis";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
