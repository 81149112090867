import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkedButton = styled(Link)`
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export default LinkedButton;
