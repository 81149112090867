import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  padding: 20px;
  text-align: center;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FooterItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  text-decoration: none;
  color: inherit;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterItem to="/aboutus">会社概要</FooterItem>
    </FooterContainer>
  );
};
export default Footer;
