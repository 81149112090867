import React from "react";
import { Head, Text, Button } from "react-styledcomponent-template";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Template from "../template";

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Page: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    navigate(`/chat/1`);
  };

  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Head>決済</Head>
        <Text>決済</Text>
        <Button color="primary" fullWidth>
          決済
        </Button>
      </Form>
    </Template>
  );
};

export default Page;
