import React, { useEffect } from "react";
import { Head, Button, Label, Input } from "react-styledcomponent-template";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Template from "../template";
import useUpdateUser from "../apis/user/update";
import useUser from "../apis/user/get";

const Container = styled.form`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

const UserImage = styled.label<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-self: center;
`;

const Page: React.FC = () => {
  const [icon, setIcon] = React.useState<string>("");
  const formRef = React.useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const user = useUser({ pathParams: { id: "me" } });
  const updateUser = useUpdateUser();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const name = (e.currentTarget?.username as HTMLInputElement)?.value;
    const company = (e.currentTarget?.company as HTMLInputElement)?.value;
    const position = (e.currentTarget?.position as HTMLInputElement)?.value;
    const introduction = (e.currentTarget?.introduction as HTMLInputElement)
      ?.value;
    const description = (e.currentTarget?.description as HTMLInputElement)
      ?.value;
    const icon = e.currentTarget?.icon?.files?.[0];

    console.log(name, company, position, introduction, description, icon);

    const result = await updateUser.execute({
      params: {
        name,
        company,
        position,
        introduction,
        description,
        icon,
      },
    });

    if (result instanceof Error) return;

    navigate(`/profile`);
  };
  const handleChangeIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    setIcon(url);
  };

  useEffect(() => {
    if (user.loading) return;
    if (!user.data) return;
    if (user.error) return;

    if (user.data?.icon) setIcon(user.data.icon);
    if (user.data?.name)
      formRef.current?.username?.setAttribute("value", user.data.name);
    if (user.data?.company)
      formRef.current?.company?.setAttribute("value", user.data.company);
    if (user.data?.position)
      formRef.current?.position?.setAttribute("value", user.data.position);
    if (user.data?.introduction)
      formRef.current?.introduction?.setAttribute(
        "value",
        user.data.introduction
      );
    if (user.data?.description)
      formRef.current?.description?.setAttribute(
        "value",
        user.data.description
      );
  }, [user]);
  return (
    <Template>
      <Container onSubmit={handleSubmit} ref={formRef}>
        <Head>プロフィール編集</Head>
        <UserImage src={icon}>
          <input
            name="icon"
            type="file"
            accept="image/*"
            hidden
            onChange={handleChangeIcon}
          />
        </UserImage>
        <Label label="名前" fullWidth>
          <Input fullWidth placeholder="名前を入力" name="username" />
        </Label>
        <Label label="会社名" fullWidth>
          <Input fullWidth placeholder="会社名を入力" name="company" />
        </Label>
        <Label label="役職" fullWidth>
          <Input fullWidth placeholder="役職を入力" name="position" />
        </Label>
        <Label label="自己紹介" fullWidth>
          <Input fullWidth placeholder="自己紹介を入力" name="introduction" />
        </Label>
        <Label label="得意分野" fullWidth>
          <Input fullWidth placeholder="得意分野を入力" name="description" />
        </Label>
        <Button color="primary" fullWidth>
          保存
        </Button>
      </Container>
    </Template>
  );
};

export default Page;
