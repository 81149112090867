import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Scan from "../pages/Scan";
import Result from "../pages/Result";
import Results from "../pages/Results";
import Case from "../pages/Case";
import Cases from "../pages/Cases";
import Payment from "../pages/Payment";
import Chat from "../pages/Chat";
import Chats from "../pages/Chats";
import Profile from "../pages/Profile";
import ProfileMine from "../pages/ProfileMine";
import ProfileEdit from "../pages/ProfileEdit";
import Company from "../pages/Company";
import Diagnosis from "../pages/Diagnosis";
import DiagnosisAdd from "../pages/DiagnosisAdd";
import NotFound from "../pages/NotFound";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/result" element={<Results />} />
        <Route path="/result/:id" element={<Result />} />
        <Route path="/case/:id" element={<Case />} />
        <Route path="/case" element={<Cases />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/chat/:id" element={<Chat />} />
        <Route path="/chat" element={<Chats />} />
        <Route path="/profile/edit" element={<ProfileEdit />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/profile" element={<ProfileMine />} />
        <Route path="/aboutus" element={<Company />} />
        <Route path="/diagnosis" element={<Diagnosis />} />
        <Route path="/diagnosis/add" element={<DiagnosisAdd />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
