import React from "react";
import { Input, Label, Text, Button } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useSignin from "../apis/auth/signin";
import Template from "../template";

const NO_EMAIL = "メールアドレスを入力してください";
const NO_PASSWORD = "パスワードを入力してください";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 0;
`;

const LinkedText = styled(Link)`
  text-decoration: none;
  color: #000;
  font-size: 14px;
`;

const Page: React.FC = () => {
  const navigate = useNavigate();
  const signin = useSignin();
  const [, setCookie] = useCookies(["login"]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity?.("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = (e.currentTarget?.email as HTMLInputElement)?.value;
    const password = (e.currentTarget?.password as HTMLInputElement)?.value;

    if (!email) {
      e.currentTarget?.email?.setCustomValidity?.(NO_EMAIL);
      e.currentTarget?.email?.reportValidity?.();
      return;
    }
    if (!password) {
      e.currentTarget?.password?.setCustomValidity?.(NO_PASSWORD);
      e.currentTarget?.password?.reportValidity?.();
      return;
    }

    const result = await signin.execute({ params: { email, password } });

    if (result instanceof Error) return;

    setCookie("login", "true");
    navigate("/");
  };
  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Label label="メールアドレス" fullWidth>
          <Input name="email" fullWidth onChange={handleChange} />
        </Label>
        <Label label="パスワード" fullWidth>
          <Input
            name="password"
            fullWidth
            type="password"
            onChange={handleChange}
          />
        </Label>
        {signin.error && <Text error>ログインエラー</Text>}
        <Button color="primary" loading={signin.loading}>
          ログイン
        </Button>
        <LinkedText to="/signup">登録はこちらから</LinkedText>
      </Form>
    </Template>
  );
};

export default Page;
