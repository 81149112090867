import React from "react";
import Template from "../template";
import { Text, Head } from "react-styledcomponent-template";
import LinkedButton from "../components/LinkedButton";

const Page: React.FC = () => {
  return (
    <Template>
      <Head>not found!</Head>
      <Text>このページは見つかりません</Text>
      <LinkedButton to="/">トップページへ</LinkedButton>
    </Template>
  );
};

export default Page;
