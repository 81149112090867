import useApi from "../../libs/useApi";

interface DataType {}
interface Params {
  to: number;
  message: string;
  requestId?: number;
  file1?: File;
  file2?: File;
  file3?: File;
  file4?: File;
}

const path = "/message";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
