import React from "react";
import { Head, Text } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Template from "../template";
import useRequests from "../apis/request/list";

const Item = styled(Link)<{ src: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;
  &:before {
    content: " ";
    display: flex;
    background-color: white;
    width: 100%;
    padding-top: ${(1 / 4) * 100}%;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
  }
`;

const Page: React.FC = () => {
  const requests = useRequests();
  const items = [
    {
      id: 1,
      src: "https://source.unsplash.com/random?sig=1",
      name: "税務関係書類1",
      price: 1000,
      description:
        "内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容",
      status: "未対応",
      destination: [
        {
          id: 1,
          name: "税理士事務所",
          img: "https://source.unsplash.com/random?sig=2",
        },
        {
          id: 2,
          name: "税理士事務所2",
          img: "https://source.unsplash.com/random?sig=3",
        },
      ],
    },
    {
      id: 2,
      src: "https://source.unsplash.com/random?sig=2",
      name: "税務関係書類2",
      price: 2000,
      description:
        "内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容",
      status: "未対応",
      destination: [
        {
          id: 1,
          name: "税理士事務所",
          img: "https://source.unsplash.com/random?sig=2",
        },
        {
          id: 2,
          name: "税理士事務所2",
          img: "https://source.unsplash.com/random?sig=3",
        },
      ],
    },
  ];

  return (
    <Template>
      <Head>依頼中・済案件一覧</Head>
      <Text>依頼中の案件一覧です。</Text>
      {requests.data
        ?.filter((i) => i.isSubmited)
        ?.map?.((i) => (
          <Item src={i?.images?.[0]} to={`/chat/${i.id}`}>
            <Text>{i?.name}</Text>
            <Text>
              見積もり相場: ￥{i?.plan?.marketPrice?.toLocaleString?.()}
            </Text>
            <Text>依頼内容：{i.description}</Text>
            <Text>状態: {i.isClosed ? "完了" : "進行中"}</Text>
          </Item>
        ))}
    </Template>
  );
};

export default Page;
