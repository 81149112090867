import React from "react";
import { Head } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Template from "../template";
import useChatrooms from "../apis/message/rooms";

const Header = styled(Head)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin: 0;
  width: 100%;
  height: 60px;
  background-color: var(--default-bg-color);
`;

const Chatrooms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Chatroom = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  text-decoration: none;
  color: var(--default-text-color);
`;
const Divider = styled.hr`
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #ccc;
`;
const Icon = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 50px;
  position: relative;
  border: 1px solid #ccc;
`;
const Name = styled.div``;

const Page: React.FC = () => {
  const rooms = useChatrooms();

  return (
    <Template>
      <Header>チャット</Header>
      <Chatrooms>
        <Divider />
        {rooms.data?.map?.((room) => (
          <>
            <Chatroom key={room.id} to={`/chat/${room.id}`}>
              <Icon src={room.icon} />
              <Name>{room.name}</Name>
            </Chatroom>
            <Divider />
          </>
        ))}
      </Chatrooms>
    </Template>
  );
};

export default Page;
