import React from "react";
import { Head, Text, Button } from "react-styledcomponent-template";
import styled from "styled-components";
import Template from "../template";
import useDiagnoses from "../apis/diagnosis/list";
import useFollowDiagnosis from "../apis/diagnosis/follow";
import useUnFollowDiagnosis from "../apis/diagnosis/unfollow";

const DiagnosisContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  border: 1px solid #5e5e5e;
  padding: 10px;
  border-radius: 10px;
`;
const DiagnosisImage = styled.div<{ src: string }>`
  display: flex;
  width: 100%;
  padding-top: 70%;
  background-color: white;
  &::before {
    content: "";
    display: block;
    z-index: 1;

    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
const DiagnosisName = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text);
  align-self: left;
`;
const DiagnosisDescription = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text);
  align-self: left;
`;
const DiagnosisPrice = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text);
  align-self: left;
`;

const Page: React.FC = () => {
  const diagnoses = useDiagnoses();
  const follow = useFollowDiagnosis();
  const unfollow = useUnFollowDiagnosis();

  const handleFollow =
    (id: number, followed: boolean) =>
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (followed) await unfollow.execute({ params: { id } });
      else await follow.execute({ params: { id } });
      await diagnoses.execute({});
    };

  return (
    <Template>
      <Head>診断一覧</Head>
      <Text>診断に登録しておくと、依頼がきます。</Text>
      {diagnoses.data?.map?.((diagnosis) => (
        <DiagnosisContainer>
          <DiagnosisImage src={diagnosis.image} />
          <DiagnosisName>{diagnosis.name}</DiagnosisName>
          <DiagnosisDescription>{diagnosis.description}</DiagnosisDescription>
          <DiagnosisPrice>参考：{diagnosis.marketPrice}円</DiagnosisPrice>
          <Button
            loading={diagnoses.loading || follow.loading || unfollow.loading}
            onClick={handleFollow(diagnosis.id, !!diagnosis?.followed)}
            fullWidth
            color={diagnosis?.followed ? "primary" : "info"}
          >
            {diagnosis?.followed ? "案件に参加中" : "参加する"}
          </Button>
        </DiagnosisContainer>
      ))}
    </Template>
  );
};

export default Page;
