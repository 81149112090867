import React from "react";
import { Input, Label, Button, Text } from "react-styledcomponent-template";
import CheckBox from "../components/Checkbox";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useSignup from "../apis/auth/signup";
import Template from "../template";
import { title } from "process";

const NO_EMAIL = "メールアドレスを入力してください";
const NO_PASSWORD = "パスワードを入力してください";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 0;
`;
const Title = styled.label`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Page: React.FC = () => {
  const navigate = useNavigate();
  const signup = useSignup();
  const [, setCookie] = useCookies(["login"]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity?.("");
  };
  const titles = ["弁護士", "税理士", "司法書士", "会計士", "社労士"];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = (e.currentTarget?.email as HTMLInputElement)?.value;
    const password = (e.currentTarget?.password as HTMLInputElement)?.value;
    const getProfessions = (): string[] => {
      return titles
        .map((title) =>
          (e.currentTarget?.[title] as HTMLInputElement)?.checked ? title : ""
        )
        .filter((title) => !!title);
    };
    const profession = getProfessions();

    if (!email) {
      e.currentTarget?.email?.setCustomValidity?.(NO_EMAIL);
      e.currentTarget?.email?.reportValidity?.();
      return;
    }
    if (!password) {
      e.currentTarget?.password?.setCustomValidity?.(NO_PASSWORD);
      e.currentTarget?.password?.reportValidity?.();
      return;
    }

    const result = await signup.execute({
      params: { email, password, profession },
    });

    if (result instanceof Error) return;

    setCookie("login", "true");
    navigate("/");
  };
  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Label label="メールアドレス" fullWidth>
          <Input name="email" type="email" fullWidth onChange={handleChange} />
        </Label>
        <Label label="パスワード" fullWidth>
          <Input
            name="password"
            fullWidth
            type="password"
            onChange={handleChange}
          />
        </Label>
        {titles.map((title) => (
          <Title>
            {title}として登録する
            <CheckBox name={title} />
          </Title>
        ))}

        {!!signup.error &&
          (signup.code === 409 ? (
            <Text error>このメールアドレスは既に登録されています</Text>
          ) : (
            <Text error>エラーが発生しました</Text>
          ))}

        <Button color="primary" loading={signup.loading}>
          登録
        </Button>
      </Form>
    </Template>
  );
};

export default Page;
