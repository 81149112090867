import { useEffect, useState } from "react";
import axiosBase, { AxiosError } from "axios";

const BASE_URL = "/api";

const axios = axiosBase.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
  responseType: "json",
});

export interface State<DataType> {
  loading: boolean;
  called: boolean;
  data: DataType | null;
  error: Error | null;
  code?: number;
}

interface PathParams {
  [key: string]: string;
}

export interface Input<ParamType> {
  pathParams?: PathParams;
  params?: ParamType;
}
export interface MethodParam<ParamType> extends Input<ParamType> {
  method: "get" | "post" | "put" | "delete";
  path: string;
  eagerLoad?: boolean;
}

interface ExecuteParam<ParamType> {
  params?: ParamType;
  pathParams?: PathParams;
}
export interface Output<DataType, ParamType> extends State<DataType> {
  execute: (e: ExecuteParam<ParamType>) => Promise<DataType | Error>;
}

export const useApi =
  <DataType, ParamType>(methodparam: MethodParam<ParamType>) =>
  (
    input?: Input<ParamType>,
    __pathParams?: PathParams
  ): Output<DataType, ParamType> => {
    const {
      path,
      method,
      eagerLoad = false,
      pathParams: initialPathParams = {},
      params: initialParams = {} as ParamType,
    } = methodparam;
    const { pathParams = initialPathParams, params = initialParams } =
      input || {};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState<State<DataType>>({
      loading: false,
      called: false,
      data: null,
      error: null,
    });
    const __params = params as ParamType;
    const execute = async (
      executeparam: ExecuteParam<ParamType>
    ): Promise<DataType | Error> => {
      try {
        const ____pathParams = executeparam.pathParams || pathParams;
        const pathReplaced = ____pathParams
          ? Object.entries(____pathParams).reduce(
              (acc, [key, value]) => acc.replace(`:${key}`, value),
              path
            )
          : path;
        setState((prev) => ({
          ...prev,
          loading: true,
          called: true,
          error: null,
          undefined,
        }));
        const p = new FormData();

        Object.entries(executeparam.params || __params || {}).forEach(
          ([key, value]) => {
            if (!(value instanceof File) && !(typeof value === "string"))
              return;
            p.append(key, value);
          }
        );

        const result = !executeparam.params
          ? await axios[method](pathReplaced)
          : method === "get"
          ? await axios[method](pathReplaced, { params: executeparam.params })
          : await axios[method](pathReplaced, executeparam.params, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
        const { data } = result;
        setState((prev) => ({ ...prev, loading: false, data }));
        return data as DataType;
      } catch (e) {
        setState((prev) => ({
          ...prev,
          loading: false,
          error: e as Error,
          code: (e as any)?.response?.status || undefined,
        }));

        if (e instanceof AxiosError && e?.response?.data?.message)
          return new Error(e.response.data.message);
        return e as Error;
      }
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (eagerLoad) execute({ params });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { execute, ...state };
  };
export default useApi;
