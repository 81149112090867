import React from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {}
const Icon: React.FC<IconProps> = (p) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...p}
  >
    <path
      d="M10 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0ZM14 18H2V2H9V7H14V18Z"
      fill="black"
    />
    <path
      d="M4.17898 15H3.25568L5.92614 7.72727H6.83523L9.50568 15H8.58239L6.40909 8.87784H6.35227L4.17898 15ZM4.51989 12.1591H8.24148V12.9403H4.51989V12.1591ZM11.5192 7.72727V15H10.6385V7.72727H11.5192Z"
      fill="black"
    />
  </svg>
);
export default Icon;
