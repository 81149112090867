import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../components/Logo";

const HeaderContainer = styled.header`
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  padding: 20px;
  text-align: center;
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: inherit;
  text-decoration: none;
  flex-direction: row;
  gap: 20px;
`;
const Header = () => {
  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <Logo /> marunage
      </LogoContainer>
    </HeaderContainer>
  );
};
export default Header;
