import useApi from "../../libs/useApi";

interface DataType {
  token: string;
}
interface Params {
  name?: string;
  company?: string;
  position?: string;
  introduction?: string;
  description?: string;
  icon?: File;
}

const path = "/user";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
