import React, { useEffect } from "react";
import {
  Head,
  Text,
  Input,
  Label,
  Button,
} from "react-styledcomponent-template";
import styled from "styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Template from "../template";
import useRequest from "../apis/request/get";
import useUsers from "../apis/user/list";
import usePostChat from "../apis/message/add";

const professions = [
  "税理士",
  "会計士",
  // "弁護士",
  // "社会保険労務士",
];

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  color: var(--color-text);
  text-decoration: none;
  cursor: pointer;
`;

const RequestModal = styled.dialog`
  width: 300px;
  max-width: 80vw;
  height: 500px;
  max-height: 80vh;
  display: none;

  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: var(--default-default-bg-color);
  color: var(--default-default-text-color);

  border: 1px solid #ccc;
  border-radius: 5px;

  &[open] {
    display: flex;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Price = styled.div``;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;
const RequestDestination = styled.label`
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  &:has(*:checked) {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
  }
`;
const UserImage = styled.div<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex: 0 0 50px;
`;
const Username = styled.div`
  display: flex;
  flex: 0 0 12px;
  font-size: 12px;
`;
const LinkToProfile = styled(Link)`
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
  background-color: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 5px;
`;

const RequestForm = styled.form`
  padding: 10px;

  gap: 10px;
  display: flex;

  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

const ProfessionSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const ProfessionSelectorTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 20px;
`;

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("id is required");
  const modalRef = React.useRef<HTMLDialogElement>(null);
  const request = useRequest({ pathParams: { id: `${id}` } });
  const postChat = usePostChat();
  const users = useUsers();
  const navigate = useNavigate();

  const [target, setTarget] = React.useState<string>("");

  const openModal = (target: string) => {
    setTarget(target);
    modalRef.current?.showModal();
  };
  const handleClickItem =
    (target: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      openModal(target);
    };
  const handleClickModal = (e: React.MouseEvent<HTMLDialogElement>) => {
    // console.log(e);
    const modal = e.target as HTMLElement;
    const isDialogElement = modal instanceof HTMLDialogElement;
    const rect = modal.getBoundingClientRect();

    const isInDialog =
      rect.top <= e.clientY &&
      e.clientY <= rect.bottom &&
      rect.left <= e.clientX &&
      e.clientX <= rect.right;

    if (!isDialogElement) return;

    if (isInDialog) return;

    modalRef.current?.close?.();
  };

  const R = request?.data?.diagnosisjson?.find((i) => i.name === target);

  useEffect(() => {
    users.execute({ params: { profession: target } });
  }, [target]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const destination = form.destination?.value;
    const description = form.description?.value;
    if (!destination) return alert("依頼先を選択してください");
    if (!description) return alert("詳細を入力してください");

    const to = parseInt(destination);

    const result = await postChat.execute({
      params: {
        requestId: parseInt(id),
        to,
        message: description,
      },
    });
    if (result instanceof Error) return alert("エラーが発生しました");
    navigate(`/chat/${to}`);
  };

  return (
    <Template>
      <Head>診断結果</Head>
      <Text>aiが診断した結果です。</Text>
      <Text>{request?.data?.DiagnosisResponse}</Text>
      <ProfessionSelector>
        <ProfessionSelectorTitle>
          依頼先を選んでください
        </ProfessionSelectorTitle>
        {professions.map((profession) => (
          <Item onClick={handleClickItem(profession)}>
            <Text>{profession}</Text>
          </Item>
        ))}
      </ProfessionSelector>
      <RequestModal ref={modalRef} onClick={handleClickModal}>
        <RequestForm onSubmit={handleSubmit}>
          <Head>依頼先選択</Head>
          <Label label="詳細" fullWidth>
            <Input
              placeholder="必要に応じて詳細を入力"
              fullWidth
              name="description"
            />
          </Label>
          <Text>依頼先を選んでください</Text>
          <UserContainer>
            {users?.data?.length === 0 && (
              <Text>該当するユーザーがいません</Text>
            )}
            {users?.data?.map?.((user) => (
              <RequestDestination>
                <UserImage src={user?.icon || ""} />
                <Username>{user?.name}</Username>
                <LinkToProfile to={`/profile/${user.id}`}>詳細</LinkToProfile>
                <input type="radio" name="destination" value={user.id} hidden />
              </RequestDestination>
            ))}
          </UserContainer>
          <Button color="primary" fullWidth>
            依頼する
          </Button>
        </RequestForm>
      </RequestModal>
    </Template>
  );
};

export default Page;
