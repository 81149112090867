import useApi from "../../libs/useApi";

interface DataType {
  id: number;
}
interface Params {
  id: number;
  description: string;
  plan: number;
  destination: number;
}

const path = "/request";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
