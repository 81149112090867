import useApi from "../../libs/useApi";
import Diagnosis from "../../types/Diagnosis";

type DataType = Diagnosis;
interface Params {
  email: string;
  password: string;
}

const path = "/diagnosis/:id";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
