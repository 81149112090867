import React from "react";
import { Head } from "react-styledcomponent-template";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Template from "../template";
import useUser from "../apis/user/get";

const LinkedButton = styled(Link)`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-bg-color-hover);
  }
`;

const UserImage = styled.div<{ src: string }>`
  display: flex;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-self: center;
`;
const UserName = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--color-text);
  align-self: center;
`;

const Professions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding: 0;
`;
const Profession = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--secondary-text-color);
  background-color: var(--secondary-bg-color);
  padding: 5px;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
`;

const Company = styled.div``;
const Description = styled.div``;
const Introduction = styled.div``;

const Page: React.FC = () => {
  const user = useUser({ pathParams: { id: "me" } });

  return (
    <Template>
      <Head>プロフィール</Head>
      <UserImage src={user.data?.icon || ""} />
      <UserName>{user.data?.name || "名前未設定"}</UserName>
      <Professions>
        {user.data?.profession?.map((profession) => (
          <Profession>{profession}</Profession>
        ))}
      </Professions>
      <Company>
        {user.data?.company || "会社名"} {user.data?.position || "役職未設定"}
      </Company>
      <Description>{user.data?.description || "自己紹介未設定"}</Description>
      <Introduction>{user.data?.introduction || "自己紹介未設定"}</Introduction>
      <LinkedButton to="/profile/edit">プロフィールを編集する</LinkedButton>
    </Template>
  );
};

export default Page;
