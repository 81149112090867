import React from "react";
import {
  Head,
  Text,
  Button,
  Label,
  Input,
} from "react-styledcomponent-template";
import styled, { css } from "styled-components";
import Template from "../template";
import useCreateDiagnosis from "../apis/diagnosis/create";
import Form from "../components/Form";

const ImageLabel = styled.label<{ src?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  ${({ src }) =>
    src
      ? css`
          background-image: url(${src});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 200px;
          color: rgb(0, 0, 0, 0);
        `
      : css`
          background-color: var(--default-primary-bg-color);
          color: var(--default-primary-color);
        `}
`;

const Page: React.FC = () => {
  const createDiagnosis = useCreateDiagnosis();
  const [image, setImage] = React.useState<string>("");

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const src = reader.result;
      if (!src) return;
      setImage(src as string);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = (e.currentTarget?.diagnosisname as HTMLInputElement)?.value;
    const description = (e.currentTarget?.description as HTMLInputElement)
      ?.value;
    const marketPrice = (e.currentTarget?.price as HTMLInputElement)?.value;
    const image = (e.currentTarget?.image as HTMLInputElement)?.files?.[0];
    if (!name) return alert("名前を入力してください");
    if (!description) return alert("説明を入力してください");
    if (!marketPrice) return alert("価格を入力してください");
    if (!image) return alert("画像を選択してください");
    const result = await createDiagnosis.execute({
      params: { name, description, marketPrice: Number(marketPrice), image },
    });

    if (result instanceof Error) return;

    alert("診断を作成しました");
  };
  return (
    <Template>
      <Form onSubmit={handleSubmit}>
        <Head>診断一覧</Head>
        <Label label="名前" fullWidth>
          <Input name="diagnosisname" fullWidth />
        </Label>
        <Label label="説明" fullWidth>
          <Input name="description" fullWidth />
        </Label>
        <Label label="価格" fullWidth>
          <Input name="price" fullWidth />
        </Label>
        <ImageLabel src={image}>
          画像を追加
          <input
            type="file"
            name="image"
            hidden
            onChange={handleImageChange}
            accept="image/jpg, image/jpeg, image/png, image/gif"
          />
        </ImageLabel>
        <Button color="primary" fullWidth>
          検索
        </Button>
      </Form>
    </Template>
  );
};

export default Page;
