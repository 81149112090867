import useApi from "../../libs/useApi";
import User from "../../types/User";

type DataType = User[];
interface Params {
  profession?: string;
}

const path = "/user";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
