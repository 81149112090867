import useApi from "../../libs/useApi";

type DataType = {
  id: number;
  name: string;
  icon: string;
}[];
interface Params {}

const path = "/chat";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
